import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TonConnectButton, useTonAddress } from '@tonconnect/ui-react';

export default function MainView(userData) {
    useEffect(() => {
        console.log(userData, userData.user)
    }, [userData])
    return (
        <div className="flex h-full w-full flex-col">
            {/* Хедер */}
            <header className="relative flex w-full h-16 items-center justify-center px-4 py-2">
                {/* Название */}
                <div className="absolute left-1/2 transform -translate-x-1/2 text-white font-medium text-lg">
                    Chinor
                </div>

                {/* Кнопка подключения телеграм кошелька */}
                <div className="absolute right-4">
                    <TonConnectButton />
                </div>
            </header>

            {/* Основной контент */}
            <div className="flex-1 flex items-center justify-center">
                <p className="text-white">ID: {userData.user.username}</p>
                <Link to="/profile" className="mt-4 text-blue-500 underline">
                    Перейти в профиль
                </Link>
            </div>
        </div>
    );
}