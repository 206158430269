import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import TelegramAuthButton from './components/TelegramAuthButton';
import MainView from './components/MainView';

function App() {
  const [user, setUser] = useState(null);

  const handleAuth = async (userData) => {
    console.log('User authenticated:', userData);

    // Отправка данных на бэкенд
    try {
      const response = await fetch('https://app.chinor.fund/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const result = await response.json();

      if (result.success) {
        console.log('Backend validation successful:', result);
        setUser(userData);
      } else {
        console.error('Backend validation failed:', result.message);
      }
    } catch (error) {
      console.error('Error sending data to backend:', error);
    }
  };

  return (
    <Router>
      <div className="relative flex flex-col h-screen w-screen">
        {/* Заблюренный фон */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-gray-700"
        ></div>

        {/* Контент поверх фона */}
        <div className="flex h-full w-full z-10">
          <Routes>
            <Route
              path="/"
              element={
                  !user ? (
                    <div className='flex h-full w-full justify-center items-center'>
                      <TelegramAuthButton
                        botName="chinor2_bot"
                        buttonSize="large"
                        dataOnauth={handleAuth}
                        requestAccess="write"
                        lang="ru"
                      />
                    </div>
                  ) : (
                      <MainView user={user} />
                  )
              }
            />
            <Route
              path="/profile"
              element={
                user ? (
                  <div className="text-center">
                    <h1 className="text-white text-4xl">Профиль</h1>
                    <p className="text-white mt-4">Имя: {user.first_name}</p>
                    <p className="text-white">ID: {user.id}</p>
                    <Link to="/" className="mt-4 text-blue-500 underline">
                      Вернуться на главную
                    </Link>
                  </div>
                ) : (
                  <div className="text-center">
                    <h1 className="text-white text-4xl">Вы не авторизованы</h1>
                    <Link to="/" className="mt-4 text-blue-500 underline">
                      Перейти к авторизации
                    </Link>
                  </div>
                )
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
